/* Variables */
$main-color: #0b0b13;
$medium-color: #cccccc;
$dark-color: rgba(19, 20, 25, 1);
$bg-image: linear-gradient(to right bottom, rgba(37, 44, 55, 0.8), rgba(19, 20, 25, 0.8)), url("../../assets/images/websitebanner_00017.png");

/* Logo */
#logo {
  width: 70px;
  height: 70px;
  color: #d30707;
  white-space: nowrap;
  text-transform: uppercase;
  margin-left: 0.8rem;
}

#logo .link-header a {
  text-decoration: none;
  border-bottom: none;
}
/* Main Nav */
.nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: $bg-image;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: 4px solid hsla(338, 66%, 44%, 0.171);
  z-index: 1000;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.nav.active {
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 2;
  color: #222;
}

.nav.active p {
  color:  #000;

}

#main-nav.active a {
  color: #000;
}

#main-nav {
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;

  ul {
    display: flex;
  }

  li {
    padding: 1rem 1.5rem;
  }

  a {
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    border-bottom: 3px transparent solid;
    padding-bottom: 0.5rem;
    transition: border-color 0.5s, color 0.3s;

    &:hover {
      border-color: $medium-color;
    }

    &.active-link {
      border-color: #ba265d;
      color: #ba265d;
    }
  }
}

/* Header */
#header {
  &-home {
    height: 100vh;
    color: #fff;

    .header-content {
      text-align: center;
      padding-top: 20%;

      h1 {
        font-size: 4rem;
        line-height: 1.2;
      }
    }
  }

  /* Header Inner */
  &-inner {
    height: 5.5rem;
  }
}

/* Tablets & Smaller */
@media (max-width: 800px) {
  #header-home {
    height: 30rem;

    .header-content {
      padding-top: 5rem;
    }
  }
}

/* Landscape */
@media (max-height: 580px) {
  #header-home {
    .header-content {
      padding-top: 1rem;
    }
  }
}

/* Smartphones */
@media (max-width: 500px) {
  #main-nav {
    flex-direction: column;
    align-items: center;

    li {
      padding: 1rem;
    }
  }

  #logo {
    margin-left: -2.2rem;
  }

  #header {
    &-home {
      height: 10rem;
      background-position: 20% 30%;

      .header-content {
        display: none;
      }
    }

    &-inner {
      height: 10rem;
    }
  }

  #home-a, #about-a, #work-a, #contact-a {
    margin-top: -2rem;
    font-size: 15px;
  }
}

@media (max-width: 374px) {
  .hero {
    display: none;
  }
}

@media (max-width: 330px) {
  #home-a, #about-a, #work-a, #contact-a {
    margin-top: -3rem;
    font-size: 15px;
  }

  #logo {
    margin-right: 1rem;
  }

  #main-nav li {
    padding: 0.2rem;
  }

  .section-title {
    font-size: 1.5rem;
  }

  .lead {
    font-size: 1rem;
    margin-bottom: 2rem;
  }
}

@media (max-height: 330px) {
  #header-home {
    .header-content {
      h1 {
        font-size: 2rem;
      }
    }
  }
}
