#contact {
  &-a {
    .text-fields {
      display: grid;
      grid-template-areas:
        "name email"
        "subject phone"
        "message message";
      grid-gap: 1.2rem;
      margin-bottom: 1.2rem;

      // Grid Areas
      .name-input {
        grid-area: name;
      }
      .subject-input {
        grid-area: subject;
      }
      .email-input {
        grid-area: email;
      }
      .phone-input {
        grid-area: phone;
      }
      .message-input {
        grid-area: message;
        height: 100px;
      }

      .text-input {
        padding: 0.5rem 1rem;
      }
    }

    button[type="submit"] {
      width: 50%;
    }
  }
}



@media (max-width: 500px) {
  // Contact
  #contact {
    &-a {
      .text-fields {
        grid-template-areas:
          "name"
          "subject"
          "email"
          "phone"
          "message";
      }
    }
  }

}
