$main-color: #0b0b13;

#about-a {
  .about-info {
    display: grid;
    grid-template-areas:
      "myselImage bio bio"
      "row1 row2 row3";
    grid-gap: 1.2rem;
  }

  .about-info .my-image-cartoon {
    border-radius: 50%;
  }

  .my-image {
    grid-area: myselImage;
  }

  .bio {
    grid-area: bio;
    border-left: 3px solid $main-color;
    padding: 0.8rem;
  }

  .row-1,
  .row-2,
  .row-3 {
    grid-area: row1, row2, row3;
  }

  .row-1 .fas,
  .row-2 .fas,
  .row-3 .fas {
    color: $main-color;
    margin: 1rem;
  }
}

@media (max-width: 500px) {
  #about {
    &-a {
      .about-info {
        grid-template-areas:
          "myselImage"
          "bio"
          "row1"
          "row2"
          "row3";
      }
    }
  }
}
