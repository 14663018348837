$website-width: 1280px;

  
$medium-color: #ccc;
//$medi-color: rgba(3, 30, 60, 1);
$main-color:  rgb(11, 11, 19);
$dark-color: rgba(19, 20, 25, 1);
$light-color: hsl(216, 19%, 18%);
$bg-color: #ba265d;
// Set Text Color
@function set-text-color($color) {
  @if (lightness($color) > 50) {
    @return #000;
  } @else {
    @return #fff;
  }
}



/* 
==================
General Styles 
==================
*/
* {
  margin: 0;
  padding: 0;
}


body {
  font-family: 'Dosis', sans-serif;
  line-height: 1.6;
  background: #fff;
}

a {
  text-decoration: none;
  // color: $dark-color;
   color:  #fff;
}

ul {
  list-style: none;
}

h2,
h3,
h4 {
  text-transform: uppercase;
}

img {
  width: 100%;
}



.container {
    max-width: $website-width;
    padding: 0 1.5rem;
    margin: auto;
    overflow: hidden;
  }
  
  // Buttons
  %btn-shared {
    display: inline-block;
    padding: 0.8rem 2rem;
    transition: all 0.5s;
    border: none;
    cursor: pointer;
  }
  
  .btn {
    &-main {
      @extend %btn-shared;
      color: #333;
      background-color: $main-color;
    }
  
    &-light {
      @extend %btn-shared;
      color: #333;
      background-color: #ccc;
    }
  
    &-dark {
      @extend %btn-shared;
      color: #f4f4f4;
      background-color: $dark-color;
    }
  }
  
  button[class^='btn-']:hover,
  a[class^='btn-']:hover,
  input[class^='btn-']:hover {
    background-color: $bg-color;
  }
  
  // Backgrounds
  .bg {
    &-main {
      background: $bg-color;
      color: set-text-color($main-color);
    }
    &-dark {
      background: $dark-color;
      color: set-text-color($dark-color);
    }
    &-light {
      background: $light-color;
      color: set-text-color($light-color);
    }
    &-medium {
      background: $medium-color;
      color: set-text-color($medium-color);
    }
  }
  
  .lead {
    font-size: 1.3rem;
    margin-bottom: 2rem;
  }
  
  .text-center {
    text-align: center;
  }
  
  // Padding
  .py {
    &-1 {
      padding: 1rem 0;
    }
    &-2 {
      padding: 2rem 0;
    }
    &-3 {
      padding: 3rem 0;
    }
    &-4 {
      padding: 4rem 0;
    }
  }
  
  // Margin
  .my {
    &-1 {
      margin: 1rem 0;
    }
    &-2 {
      margin: 2rem 0;
    }
    &-3 {
      margin: 3rem 0;
    }
    &-4 {
      margin: 4rem 0;
    }
  }
  

  li {
list-style: none;
  }

  .section-title {
    font-size: 2rem;
    display: block;
    padding-bottom: 0.5rem;
    text-align: center;
    font-weight: 100;
    text-transform: uppercase;
  }
  

  

// Import Component Styles
@import '../Components/HeaderComponents/HeaderComponent';
@import '../Components/HeroComponents/HeroComponent';
@import '../Components/HomeComponents/SpecializeSectionA';
@import '../Components/HomeComponents/StasSectionB';
@import '../Components/HomeComponents/ProcessSectionC';
@import '../Components/AboutComponents/AboutMeSectionA';
@import '../Components/AboutComponents/TechnicalSkillsSectionB';
@import '../Components/AboutComponents/TestimonialsSectionC';
@import '../Components/ContactComponents/ContactFormSectionA';
@import '../Components/ContactComponents/ContactInfoSectionB';
@import '../Components/FooterComponents/FooterComponent';


