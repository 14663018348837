
// Variables
$main-color:  #0b0b13;
$light-color: hsl(216, 19%, 18%);
$medium-color: #ccc;
$dark-color: rgba(19, 20, 25, 1);
$bg-color: #ba265d;
// Home Sections

#home {
  &-c {
    .process {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 1.5rem;
      text-align: center;

      
      &-step {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 28px;
        background: $main-color;
        color: set-text-color($main-color);
        border-radius: 50%;
        height: 15px;
        width: 15px;
        line-height: 15px;
        padding: 1rem;
        transition: width 2s, height 2s, transform 2s;
      }

      
      &-icon {
        border-radius: 50%;
        background: $dark-color;
        color: set-text-color($dark-color);
        padding: 2rem;
        width: 70px;
        height: 70px;
        line-height: 70px;
        text-align: center;
        position: relative;
        transition: all 1s;
        cursor: pointer;

        &:hover {
          background: $bg-color;
          width: 90px;
          height: 90px;
          line-height: 90px;
          transform: rotate(360deg);
          border-radius: 0%;

          .process-step {
            background: $dark-color;
            color: set-text-color($dark-color);
          }
        }
      }
    }
  }
}
.bg-dark {
  background: $bg-color;

}

@media (max-width: 580px) {
 
 #home-c .process {
    grid-template-columns: 1fr;
  }
}