$dark-color: rgba(19, 20, 25, 1);
$main-color:  #0b0b13;
$bg-color: #ba265d;
// Footer
#main-footer {
  background: $dark-color;
    color: #fff;
    height: 5rem;
  
    .footer-content {
      display: flex;
      justify-content: space-between;
      height: 5rem;
      align-items: center;
  
      .social .fab {
        margin-right: 1rem;
        border: 2px #fff solid;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        line-height: 20px;
        text-align: center;
        padding: 0.5rem;
  
        &:hover {
          background: $bg-color;
        }
      }
    }
  }

   // Smartphones
   @media (max-width: 580px) {
   
    // Footer
    #main-footer {
      height: 7rem;
  
      .footer-content {
        flex-direction: column;
        padding: 1rem;
        height: 5rem;
      }
    }
  }