// Variables
$light-color: hsl(216, 19%, 18%);
$medium-color: #ccc;
$tertiary-color: #ba265d; 
$primary-color: #0b0b13;


#home {

  &-b {
    .stats {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 0.5rem;
      li {
        line-height: 2;
        color: #fff;
        &:nth-child(even) {
          color: #fff;
        }
        &.stats-title {
          font-size: 1.5rem;
        }
        &.stats-number {
          font-size: 2rem;
          font-weight: bold;
        }

        svg.svgColor {
          width: 70px; 
          height: 70px; 
         fill: #fff;
        }
      }

      div {
        padding: 3rem 0;

        &:nth-child(odd) {
          background: $tertiary-color;
        }

        &:nth-child(even) {
          background: $primary-color;
          color: #fff;
        }
      }
    }
  }
}

@media (max-width: 500px) {
 
  #home-b .stats {
    grid-template-columns: 1fr;
  }

  #home-b  {
    .stats div {
      padding: 2rem 0 1rem 0;
    }
  }
}
