/* 
===========
Hero Section
===========
*/
$bg-image:  linear-gradient(to right bottom, rgba(37, 44, 55, 0.8), rgba(19, 20, 25, 0.8)), url("../../assets/images/showcase.webp");

.hero {
  position: relative;
  background-image: $bg-image; 
  background-position: center; 
  background-repeat: no-repeat; 
  background-size: cover; 
  height: 100vh;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.hero h1 {
  font-size: 46px;
  margin: -20px 0 20px;
}

.hero p {
  font-size: 20px;
  letter-spacing: 1px;
}

.svg-icon path:nth-child(1) {
  fill: #ff0000;
}
.svg-icon path:nth-child(2) {
  fill: #00ff00;
  font-size: 2rem;
}

@media (max-width: 500px) {
  .hero {
    margin-top: -3rem;
  }
  .hero h1 {
    margin-top: 0.4rem;
    font-size: 30px;
  }
}

@media (max-width: 375px) {
  .hero h1 {
    margin-top: 0.4rem;
    font-size: 30px;
  }
}

@media (max-width: 374px) {
  .hero {
    display: none;
  }
}
