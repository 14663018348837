$color-secondary: #19376d;
$color-text: #fff;
$main-color: #0b0b13;
$dark-color: rgba(19, 20, 25, 1);

.skillsContainer {
  color: $color-text;
  margin-top: 79px;
  margin-left: 10%;
  margin-right: 10%;
}

.title {
  color: $color-text;
  font-size: 35px;
  font-weight: 700;
  letter-spacing: 1.75px;
  text-transform: uppercase;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 14px;
}

.skills {
  width: 45%;
  display: flex;
  flex-wrap: wrap;
  gap: 37px;
}

.skill {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 11px;
}

.skillImageContainer {
  background-color: $color-secondary;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0rem;
  width: 120px;
  height: 120px;
}

.skills-txt-color {
  color: #fff;
}

.skillImageContainer img {
  width: 60px;
}

.skill p {
  font-size: 16px;
  margin-left: 0.2rem;
  font-weight: 500;
  color: #fff;
}

.history,
.tools {
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.historyItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 17px;
  background: #19376d;
  background: linear-gradient(
    90deg,
    rgb(11, 11, 19) 0%,
    rgba(19, 20, 25, 0.863) 100%
  );
  border-radius: 10px;
  padding: 24px;
  color: #fff;
}

.historyItem img {
  width: 60px;
}



.historyItemDetails h3 {
  font-size: 20px;
  font-weight: 500;
}

.historyItemDetails p {
  font-size: 18px;
  font-weight: 300;
}

.historyItemDetails ul,
.tools  {
  margin-top: 6px;
  font-size: 16px;
  padding-left: 0;
}

.experiences {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 10px;
  list-style-type: none;
  padding-left: 0;
}

@media screen and (max-width: 830px) {
  .content {
    flex-direction: column;
    align-items: center;
    gap: 34px;
  }

  .skills {
    width: 100%;
    flex-direction: row;
    justify-content: center;
  }

  .history,
  .tools  {
    width: 100%;
    gap: 9px;
  }
}

@media screen and (max-width: 500px) {
  .experiences {
    grid-template-columns: 1fr;
  }
}
